import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Image, Row, Col, Carousel} from 'react-bootstrap';
function App() {
  return (
      <>
          <Container>
              <Row style={{marginTop: 20}}>
                  <Col md={1}></Col>
                  <Col md={10} style={{textAlign: "center"}}>
                      <Image src={logo} style={{width: 400, height: "auto"}} />
                  </Col>
                  <Col md={1}></Col>
              </Row>
              <Row style={{marginTop: 20}}>
                  <Col xs={6} style={{textAlign: "right", paddingRight: 20}}>
                      <a href='https://play.google.com/store/apps/details?id=com.magixit.gymanize&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                          <Image alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' style={{width: 180, height: "auto"}}/>
                      </a>
                  </Col>
                  <Col xs={6} style={{textAlign: "left", paddingLeft: 20}}>
                      <a href="https://apps.apple.com/us/app/gymanize/id1546552959?itsct=apps_box&amp;itscg=30200"
                         style={{display: "inline-block", overflow: "hidden", borderRadius: 13, marginTop: 12, width: 150, height: "auto"}}>
                          <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1611705600&h=1773029cb45dc60258f197a45dd46beb" alt="Download on the App Store" style={{borderRadius: 13, width: 150, height: "auto"}} />
                      </a>
                  </Col>
              </Row>
              <Row  style={{marginTop: 25}}>
                  <Col md={4}></Col>
                  <Col md={4}>
                      <Carousel>
                        <Carousel.Item>
                            <img src="screenshot2.jpeg" style={{width: "100%", height: "auto"}}/>
                        </Carousel.Item>
                          <Carousel.Item>
                              <img src="screenshot3.jpeg" style={{width: "100%", height: "auto"}}/>
                          </Carousel.Item>
                          <Carousel.Item>
                              <img src="screenshot4.jpeg" style={{width: "100%", height: "auto"}}/>
                          </Carousel.Item>
                          <Carousel.Item>
                              <img src="screenshot5.jpeg" style={{width: "100%", height: "auto"}}/>
                          </Carousel.Item>
                          <Carousel.Item>
                              <img src="screenshot6.jpeg" style={{width: "100%", height: "auto"}}/>
                          </Carousel.Item>
                      </Carousel>
                  </Col>
                  <Col md={4}></Col>
              </Row>
              <Row style={{marginTop: 50}}>
                  <Col style={{textAlign: "center", fontSize: 8}}>
                      Gymanize is a trademark of MagixIT LLC. Google Play and the Google Play logo are trademarks of Google LLC.
                  </Col>
              </Row>
           </Container>
      </>

  );
}

export default App;
